"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuizResponsesGetAttachmentUploadUrl = exports.QuizResponsesGetAttachmentUploadUrlOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.QuizResponsesGetAttachmentUploadUrlOperationId = 'QuizResponsesGetAttachmentUploadUrl';
/**
 * Get a signed URL for uploading an attachment to a quiz response
 *
 * @path /quiz_responses/upload-attachment-url
 * @generated
 */
exports.QuizResponsesGetAttachmentUploadUrl = (0, ClientController_1.ClientControllerFactory)({
    path: '/quiz_responses/upload-attachment-url',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 500],
});
