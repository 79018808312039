"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuizResponsesListQuizResponses = exports.QuizResponsesListQuizResponsesOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.QuizResponsesListQuizResponsesOperationId = 'QuizResponsesListQuizResponses';
/**
 * List quiz responses for a given booking or order
 *
 * @path /quiz_responses
 * @generated
 */
exports.QuizResponsesListQuizResponses = (0, ClientController_1.ClientControllerFactory)({
    path: '/quiz_responses',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 500],
});
